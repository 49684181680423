export const subRoute = [
  {
    title: "DIGITAL SAFETY",
    cName: "linked",
    path: "/student_life/Safety",
  },
  {
    title: "LUNCH MENU",
    cName: "linked",
    path: "/student_life/Lunch",
  },
];
